import { Injectable } from '@angular/core';

const prefixes = [
    'https://sessions.rezbot.com',
    'https://inline.bookit.geronigo.com',
    'https://avail.rezbot.com',
    'https://vouchers.rezbot.com',
    'https://contact.rezbot.com',
    'https://multibook.rezbot.com',
    'https://promos.rezbot.com/',
    'https://listbook.rezbot.com/',
    'https://geronigo.gitlab.io/promo-banner/',
];

const skip = ['https://multibook.rezbot.com', 'https://listbook.rezbot.com/'];

@Injectable()
export class ScriptsService {
    public scripts: { src: string, loaded: boolean }[] = [];

    constructor() {
        const script = document.createElement('script');
        const supports = 'noModule' in script;

        this.scripts = prefixes.map(item => {
            //let src = `${item}/wc/${supports ? 'wc-es2015.js' : 'wc-es5.js'}?version=${(new Date()).getTime()}`;
            let src = `${item}/wc/wc.js?version=${(new Date()).getTime()}`;

            if (skip.includes(item)) src = `${item}/wc/wc.js?version=${(new Date()).getTime()}`;

            return { src, loaded: false };
        });
    }

    load() {
        this.scripts.forEach((item, i) => {
            if (item.loaded) return;

            const script = document.createElement('script') as any;

            script.type = 'text/javascript';
            script.src = item.src;

            document.getElementsByTagName('head')[0].appendChild(script);

            this.scripts[i].loaded = true;
        });
    }
}
