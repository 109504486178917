import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Store } from '@ngxs/store';
import { of, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/ru.js';

import { Language } from '../models/directories.model';
import { UserState } from '@app/store/user.state';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    public langs: Language[] = [];
    public locale = 'en';
    public importedLocales: { [key: string]: boolean } = {
        en: true
    };

    constructor(
        private store: Store,
        private translateService: TranslateService
    ) {
        try{
            this.langs = this.store.selectSnapshot(UserState.directory('languages'));
        }catch{
            //quite often the directories arent saved yet
        }

        // this.setLocale('ru');
    }

    public setLocale(lang = 'en'): void {
        // this.translateService.use(lang);

        (this.importedLocales[lang] ? of(null) : combineLatest([
            import(
                /* webpackInclude: /@angular\/common\/locales\/(ru|zh)\.js$/ */
                `/node_modules/@angular/common/locales/${lang}.js`
            ),
            import(
                /* webpackInclude: /moment\/locale\/(ru|zh)\.js$/ */
                `moment/locale/${lang}.js`
            )
        ])).subscribe(([res]) => {
            if (res) registerLocaleData(res.default, lang);

            moment.locale(lang);

            this.importedLocales[lang] = true;
            this.locale = lang;
        });
    }
}
