import { HttpClient } from '@angular/common/http';
import { Injectable, ErrorHandler, OnDestroy } from '@angular/core';
import { from, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import * as StackTrace from 'stacktrace-js';

import { Profile } from '../models/profile.model';
import { ApiService } from './api.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
    private errorSubject: Subject<Error> = new Subject();
    private errorSub: Subscription;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
    ) {
        this.errorSub = this.errorSubject.pipe(
            throttleTime(5000),
            switchMap(err => this.logError(err))
        ).subscribe({next:() => {}, error:() => {}});
    }

    ngOnDestroy() {
        if (this.errorSub) this.errorSub.unsubscribe();
    }

    handleError(error: Error) {
        console.error('handlerror',error);

        if (error.name === 'HttpErrorResponse') return;
        if(error.message.indexOf('Too many connections') >= 0) return; //lets not spam ourselves

        this.errorSubject.next(error);
    }

    private logError(error: Error) {
        const profile: Profile = JSON.parse(localStorage.getItem('@@STATE'))?.user?.profile;

        return from(StackTrace.fromError(error)).pipe(
            catchError(() => of(null)),
            map(stackframes => {
                if (!stackframes) return null;
                else return stackframes.splice(0, 20).map(val => val.toString());
            }),
            switchMap(stackframes => {
                const message = [
                    `URL: ${window.location.href}`,
                    `Widget Name: RezBot`,
                    profile ? `Auth User: ${profile.admin_email} (${profile.company_id})` : null,
                    `API URL: ${this.apiService.activeVersion?.url} (${this.apiService.activeVersion?.name})`,
                    `Error Message: ${error.message}`,
                    stackframes ? `\`\`\`${stackframes.join('\n')}\`\`\`` : null
                ].filter(Boolean).join('\n');

                if (window.origin === 'http://localhost:4200') return of(null);

                return this.http.get('https://apidev2.geronigo.com/en/action_query/slack/outOfMemory', {
                    observe: 'response',
                    params: { no_intercept: '1', no_intercept_all: '1', channel: '#wc-gcms', message }
                });
            })
        );
    }
}
