import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import { switchMap, retryWhen, timeout, retry } from 'rxjs/operators';
import * as moment from 'moment';

import { UserState } from '@app/store/user.state';

import { HttpConfig } from '../models/http-config.model';
import { ApiService } from './api.service';
import { Profile } from '../models/profile.model';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    public globalRefresh: Subject<boolean> = new Subject();
    public superChange: Subject<{ admin_id: string, company_id: string, privileges: string }> = new Subject();

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store
    ) { }

    public get isTest(): boolean {
        return this.apiService.isTest;
    }

    public get isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    public get baseUrl(): string {
        if (this.apiService.activeVersion) return this.apiService.activeVersion.url;

        return '';
    }

    public get stripeKey(): string {
        return this.isTest ? 'pk_test_QAmYZ8RueSG1pfIAmVphPhhA' : 'pk_live_PnJYZ5rubpGusVQFXRLwNoUU';
    }

    public get userProfile(): Profile {
        return this.store.selectSnapshot(UserState.profile);
    }

    public get(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        let _timeout = 30000;
        if(url.indexOf('availabilities') > -1){
            _timeout = 90000;
        }


        return this.http.get<any>(`${url.startsWith('http') ? '' : this.baseUrl}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        }).pipe(
            timeout(_timeout),
            retry({count:2, delay:(error, i) => {                
                const user = this.store.selectSnapshot(UserState.profile);
                
                if (!user || error.status !== 401 || i > 2) return throwError(()=>error);

                return timer((i + 1) * 500);
            }})
        );
    }

    public getFile(url: string, config?: HttpConfig) {
        return this.http.get(`${url.startsWith('http') ? '' : this.baseUrl}${url}`, {
            responseType: 'blob', observe: 'response', ...this.parseConfig(config)
        }).pipe(
            retry({count:2, delay:(error, i) => {                
                const user = this.store.selectSnapshot(UserState.profile);
                
                if (!user || error.status !== 401 || i > 2) return throwError(()=>error);

                return timer((i + 1) * 500);
            }})
        );
    }

    public post(url: string, body: any, config?: HttpConfig ): Observable<HttpResponse<any>> {
        return this.http.post<any>(`${url.startsWith('http') ? '' : this.baseUrl}${url}`, body, {
            observe: 'response', ...this.parseConfig(config)
        });
    }

    public patch(url: string, body: object, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.patch<any>(`${url.startsWith('http') ? '' : this.baseUrl}${url}`, this.filterParams(body), {
            observe: 'response', ...this.parseConfig(config)
        });
    }

    public put(url: string, body: object, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.put<any>(`${url.startsWith('http') ? '' : this.baseUrl}${url}`, this.filterParams(body), {
            observe: 'response', ...this.parseConfig(config)
        });
    }

    public delete(url: string, config?: HttpConfig): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${url.startsWith('http') ? '' : this.baseUrl}${url}`, {
            observe: 'response', ...this.parseConfig(config)
        });
    }

    public enocodeJSON(element: any, key?: string, list?: any): any {
        list = list || {};

        if (typeof (element) === 'object') {
            for (const idx in element) this.enocodeJSON(element[idx], key ? key + '[' + idx + ']' : idx, list);
        } else {
            list[key] = encodeURIComponent(element);
        }

        return list;
    }

    public isJson(str: string): any {
        try {
            return JSON.parse(str);
        } catch (error) {
            return str;
        }
    }

    private parseConfig(config?: HttpConfig): HttpConfig {
        const newConfig: HttpConfig = {
            headers: { 'X-APP-KEY': 'geronigocms' },
            params: { nocache: '1' }
        };

        //newConfig.params.dumpquery=1

        if (!config) return newConfig;

        if (config.params) newConfig.params = { ...newConfig.params, ...this.filterParams(config.params) };
        if (config.headers) newConfig.headers = { ...newConfig.headers, ...this.filterParams(config.headers) };
        if (config.body) newConfig.body = config.body;

        return newConfig;
    }

    private filterParams(params: { [key: string]: any }): any {
        const data: any = {};

        Object.keys(params).forEach(key => {
            if (!params.hasOwnProperty(key) || params[key] === undefined) return;

            if (params[key] === true || params[key] === false) data[key] = params[key] ? '1' : '0';
            else if (params[key] instanceof Date) data[key] = moment(params[key]).format('YYYY-MM-DD');
            else if (moment.isMoment(params[key])) data[key] = params[key].format('YYYY-MM-DD');
            else if (typeof data[key] === 'object' && !Array.isArray(data[key])) data[key] = this.filterParams(data[key]);
            else data[key] = params[key];
        });

        return data;
    }

    /*private retry(attemps: Observable<any>) {
        return attemps.pipe(
            switchMap((error, i) => {                
                const user = this.store.selectSnapshot(UserState.profile);
                
                if (!user || error.status !== 401 || i > 1 || true) return throwError(error);

                console.log('retry');

                return timer((i + 1) * 500);
            })
        );
    }*/
}
